.navbar{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}
.container.header{
    padding-right: 3.5rem;
}
.left-navbar-item-logout {
    color: #979EAD;
    padding-right: 1.5rem;
    align-items: center;
    font-size: 1rem;
    cursor: pointer;
}
.vsDemo{
    font-size: 2rem;
    font-weight: 700;
    font-family: "Avenir", sans-serif;
}
// .svg-gray{
//     width: 50%;
// }
.div-nav{
    display: flex;
    align-items: center;
}
.welcome{
    padding-right: 2rem;
    font-family: "Avenir", sans-serif;
}   
@media screen and (min-width: 1060px){
.navbar {
   width: auto !important;
}
}

