
/// category

.detected-categories-p {
    padding-bottom: 2rem;
    padding-top: 2rem;
    margin-bottom: 0;
    color: #424b54;
    font-size: 1.7rem;
    font-family: "Avenir",sans-serif;
    font-weight: 500;
}
.detected-categories-tab {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    margin-bottom: 2rem;
}

.detected-categories-li {
    background-color: #fff;
    color: $color-vs;
    border-radius: 5rem;
    margin-right: 1rem;
    border: 0.2rem solid;
    padding-top: 0.4rem;
    padding-bottom: 0.4rem;
    padding-right: 1.2rem;
    font-weight: 600;
    font-size: 1.5rem;
    cursor: pointer;
}

.detected-categories-li-active {
    background-color: #fff;
    color: #8e9aaf;
    border-radius: 5rem;
    margin-right: 1rem;
    border: 0.2rem solid;
    padding-top: 0.4rem;
    padding-bottom: 0.4rem;
    padding-right: 1.2rem;
    font-weight: 600;
    font-size: 1.5rem;
    cursor: pointer;
}
.numberSpan {
    padding-top: 0.3rem;
    padding-bottom: 0.3rem;
    padding-left: 0.8rem;
    padding-right: 0.8rem;
    background-color: $color-vs;
    color: white;
    border-radius: 5rem;
    margin-right: 0.5rem;
    margin-left: 0.4rem;
    font-weight: 600;
}
.numberSpan-active {
    padding-top: 0.3rem;
    padding-bottom: 0.3rem;
    padding-left: 0.8rem;
    padding-right: 0.8rem;
    background-color: #8e9aaf;
    color: white;
    border-radius: 5rem;
    margin-right: 0.5rem;
    margin-left: 0.4rem;
    font-weight: 600;
}

/////

.grid-section {
    position: relative;
    background: #fff;
    -webkit-box-shadow: 0 1px 2px 0 rgb(34 36 38 / 15%);
    box-shadow: 0 1px 2px 0 rgb(34 36 38 / 15%);
    margin: 5rem auto;
    padding: 1em 1em;
    padding-bottom: 4rem;
    border-radius:2rem;
    border: 1px solid rgba(34,36,38,.15);
    min-height: 25rem;
}
.gridImage {
    border-radius: 2rem;
    display: block;
    height: 100%;
    top: 50%;
    width: 100%;
}


.uploaded-image{
    max-width: 40rem;
    max-height: 100rem;
    padding-top:3rem;
    padding-left: 3rem;
    margin-bottom: 3rem;
    // display: flex;
    // justify-content: center;
}

// B-Box
.bounding-box-active {
    display: table;
    text-align: center;
    border: solid 3.5px white;
    /* opacity: 0.3; */
    position: absolute;
    z-index: 1;
    border-radius: 4px;
    color: white;
    font-weight: bold;
    border-color: #556fb5 !important;
    margin-top: 4rem;
    margin-left: 4rem;
}
.bounding-box {
    display: table;
    text-align: center;
    border: solid 3.5px white;
    opacity: 0.7;
    position: absolute;
    z-index: 1;
    border-radius: 4px;
    color: white;
    font-weight: bold;
    cursor: pointer;
    margin-top: 3rem;
    margin-left: 3rem;
}
.right-block{
    margin: 1rem 0 ;
}
.VScategory {
    font-size: 1.7rem;
    font-weight: 700;
    margin-bottom: 0px;
    text-transform: capitalize;
}
.not-linked-products {
    padding-bottom: 2rem;
    color: #52489c;
    font-size: 8px;
}
//img-res

.ui.grid.vs .five.wide.column.vs {
    padding-top: 3rem;
    padding-bottom: 2rem;
}
.VS-grid-small-imgs {
    width: 100%;
    height: 100%;
}