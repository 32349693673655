@media screen and  (min-width: 1500px) {
    .ui.container.sr{
        width: $content-width;
    }
    .navbar{
        width: $content-width !important;
    }
    .u-center-text.container.segment.section-header{
        width: $content-width;
    }
    .columnImg{
        width: $content-width;
    }
    // .ui.container.segment.grid-section{
    //     width: $content-width;
    // }
}
@media screen and  (min-width: 1300px) {
    .navbar{
        width: $content-width !important;
    }
    .ui.container.sr{
        width: $content-width;
    }
    // .ui.container.segment.grid-section{
    //     width: $content-width;
    // }
}
@media screen and  (min-width: 1251px) and (max-width:1299px) {
    // .navbar{
    //     width: 120rem !important;
    // }
    // .ui.container.segment.grid-section{
    //     width: 120rem;
    // }
    .ui.grid>[class*="sixteen wide"].column {
        padding:0;
    }
    .container{
        max-width: 120rem !important;
        width: 120rem !important;
        .img{
            width: 15rem;
        }
    }
    .ui.container.sr{
        width: 120rem;
    }
}
@media screen and  (min-width: 1201px) and (max-width:1250px) {
    .navbar{
        width: 115rem !important;
    }
    // .ui.container.segment.grid-section{
    //     width: 115rem;
    // }
    .ui.grid>[class*="sixteen wide"].column {
        padding:0;
    }
    // .container, .columnImg{
    //     max-width: 115rem !important;
    //     width: 115rem !important;
    //     .img{
    //         width: 14rem;
    //     }
    // }
    .form-field{
        width:115rem;
        .columnUploadedImageInput{
            width:78%;
        }
    }
    .inputLink{
        width: 100% !important;
    }
    .ui.container.sr{
        width: 115rem;
    }
}

@media screen and  (min-width: 1060px) and (max-width:1199px) {
    .navbar{
        width: 110rem !important;
        .li-item{
            padding: 2rem;
            font-size: 1.7rem;
        }
    }
    // .ui.container.segment.grid-section{
    //     width: 110rem;
    // }
    .ui.grid>[class*="sixteen wide"].column {
        padding:0;
    }
    // .container, .columnImg{
    //     max-width: 110rem !important;
    //     width: 110rem !important;
    //     .img{
    //         width: 13rem;
    //     }
    // }
    .form-field{
        width:110rem;
        .columnUploadedImageInput{
            width:78%;
        }
    }
    .inputLink{
        width: 100% !important;
    }
    .ui.container.sr{
        width: 110rem;
    }
}
@media screen and  (min-width: 1009px) and (max-width:1060px) {
    .navbar{
        width: 100rem !important;
        .li-item{
            padding: 1rem;
            font-size: 1.6rem;
        }
    }
    // .ui.container.segment.grid-section{
    //     width: 100rem;
    // }
    .ui.grid>[class*="sixteen wide"].column {
        padding:0;
    }
    // .container, .columnImg{
    //     max-width: 100rem !important;
    //     width: 100rem !important;
    //     .img{
    //         width: 12rem;
    //     }
    // }
    .form-field{
        width:100rem;
        .columnUploadedImageInput{
            width:78%;
        }
    }
    .inputLink{
        width: 100% !important;
    }
    .ui.container.sr{
        width: 100rem;
    }
}