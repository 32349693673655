.home{
    padding: 2rem 0;
}
// UPLOAD
.header{
    border: 2px dashed #E1E1E1;
    border-radius: 4rem;
    padding: 5rem auto;
    // margin: 2rem 0;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2rem;
    &.at{
        width: 75%;
    }
    position: relative;
}
.dropzone{
    width: 60%;
    padding: auto 2rem;
}
.segment.sm-img{
    width: 40% !important;
    padding-left: 2rem;
    .pictures-p-tag{
        padding-bottom: 1.5rem;
    }
    .columnImg{
    display: inline-flex;
    width: 100%;
    justify-content: space-between !important;
    .img-div{
    
            // min-width: 10%; 
            box-sizing: border-box;
            object-fit: contain;
            padding-right: 0.5rem;
        
        img{
            // width: 7rem;
            // height: 10.5rem;
            // padding: 3px;
            width: 100%;
            height: 9.5rem;
            object-fit: cover;
        }
    }
}}
.columnUploadedImageInput {
    height:4.5rem;
    text-align: start!important;
    display: flex;
    position: relative;
    margin-top: 2rem ;
    width: 90%;
}
.inputLink {
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 2rem;
    font-size: 1.7rem;
    outline: none;
    padding: 1rem;
}
.columnUploadedImageInput .inputLink {
    background-color: #f2f2f2;
    padding-left: 2rem;
    text-align: start;
    width: 100%;
    font-family:  Avenir-Light, sans-serif;;
}
// input[type=text] {
//     -webkit-appearance: none;
//     -moz-appearance: none;
// }
.columnUploadedImageInput .urlButtonAutomatic {
    height: 4.5rem;
    position: absolute;
    right: -1px;
    width: 15.5rem;
    background: #fff;
    border: 2px solid rgba(85,112,184,.12);
    border-radius: 2.5rem;
    cursor: pointer;
    font-size: 1.6rem;
    font-weight: 900;
    margin-bottom: 0;
    margin-left: 1.5rem;
    outline: none;
    text-align: center;
    transition-duration: .4s;
    color: $color-vs;
    font-family: Avenir-Light,sans-serif;
}
// upload progress
.uploadingProgress{
    height: 100%;
    width: 80%;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.uploading{
    text-align: center;
}
.progress{
    width: 90%;
    height: 2.5rem;
    margin: 2rem auto;
    background-color: $color-input;
}
.uploadMsg{
    padding: 1rem;
    font-size: 16px;
    color: $color-vs;
    font-weight: 700;
    text-align: center;
}
.completed-progress {
    animation: load 1.2s normal forwards;
    box-shadow: 0 10px 40px -10px $color-vs;
    border-radius: 100px;
    background: $color-vs;
    height: 2.5rem;
    width: 0;
  }
  
  @keyframes load {
    0% { width: 0; }
    100% { width: 100%; }
  }
  .show {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
}
.hide {
    display: none;
}
.upload.at .show{
    justify-content: center !important;
    align-items: center !important;
}
.comleted{
    width: 100%;
}
.progress {
    height: 100%;
    border-radius: 1rem;
    width: 100%;
}

//@at-root

// UPLOAD
label.columnUploadedImageButton {
    align-items: center;
    display: flex;
    justify-content: center;
}
.columnUploadedImageButton {
    background: $color-vs;
    border-radius: 3rem;
    color: #fff;
    cursor: pointer;
    float: left;
    font-family: Avenir-Light,sans-serif;
    font-size: 1.6rem;
    font-weight: 900;
    height: 4.5rem;
    line-height: 4.5rem;
    
    text-align: center;
    transition-duration: .4s;
    width: 19.5rem;
}
.inputfile {
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    width: 0.1px;
    z-index: -1;
}
.uploadContainer{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.columnUploadedImageText{
    margin-top: 2rem;
}