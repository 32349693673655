.ui.grid.main .twelve.wide.column, .right-padding {
.gridWrapper{
    
    border: none;
    @media (min-width:769px) {
        height: 100%;
        // width: 73vw;
        max-width: 100%;
        margin-left: 2rem;
        justify-content: flex-start;
        align-items: flex-start;
        margin:0;
        padding-left: 3rem;

      
      }
      @media only screen and (max-width:768px) {
        max-width: 100vw;   
        margin-left: 3rem;
        margin-right: 2rem; 

      }
    }
}
.pointer{
    cursor: pointer;
}

.categoryTitleWrapper{
    display: flex;
    justify-content: left;
    align-items: left;
    align-content: left;
    padding: 1rem;
    margin: 4rem 0rem 2rem;
}

.categoryTitle{
    text-transform: capitalize;
    color: rgb(66, 75, 84);
    // margin-top: 1.2rem;
    font-size: 2rem;        
    // line-height: 2.5rem;
    font-weight: 900;
    text-align: center;
}
.ui.column.grid>[class*="eight wide"].column, .ui.grid>.column.row>[class*="eight wide"].column, 
.ui.grid>.row>[class*="eight wide"].column, .ui.grid>[class*="eight wide"].column.p-img {
  width: 40%!important;
}
.ui.column.grid>[class*="eight wide"].column, .ui.grid>.column.row>[class*="eight wide"].column, 
.ui.grid>.row>[class*="eight wide"].column, .ui.grid>[class*="eight wide"].column.s-img {
  width: 60%!important;
}
.ui.grid.vs{
  width: auto !important;
  width:  100% !important;
  box-sizing: border-box;
}
.cardWrapper
{
  width: auto !important;
  width: 33% !important;
  box-sizing: border-box;
  cursor: pointer;
}
.cardWrapper{
  margin-top: 2rem !important;
}
.productsGrid{
    border:none;
    display: grid;
    grid-gap: 3.5rem;
    grid-template-columns: repeat(auto-fill,minmax(25rem,1fr));
    padding-top: 3rem;
    padding-bottom: 2rem;
   
      @media only screen and (max-width:768px) {
        grid-gap: 2rem;
        max-width: 100vw;
        padding: 3rem;
        // padding-left: 3rem;
        justify-content: center;
        align-items: center;
        
      }
      @media screen and (max-width:1339px) {
        grid-template-columns: repeat(auto-fill,minmax(24rem,1fr));
      }
}

.styledParagraph {
    font-size: 1.67rem;
}
.cardExtraWrapper{
    border-radius: 4px;
}
.cardExtra{
  margin: 1rem 1rem;
  height: 39rem;
  border-radius: 2rem!important;
 
}

.cardExtra-at {
    height: 43.5rem;
    .headerExtra{
      justify-content: space-between;
    }
    .content{
      margin-top: 0.5rem !important;
    }
    .product-status{
      padding: 0.8rem;
    }
    .navbar-dash-btn .delete{
      margin-top: 1.5rem !important;
    }
 
}

.carousel .slide img {
  border: 1.5px solid rgb(230, 230, 230)!important;
}
.imgExtra{
    border-radius: 2rem;
    // border: 1.5px solid rgb(230, 230, 230)!important;
    overflow: hidden;
    width: 100%!important;
    height: 100%!important;
    object-fit: cover!important;
    // object-fit:contain!important;
    cursor: pointer;
  
    img{
      width: 50%;
    }
}
.id-flex{
  width: 100%;
  display: flex;
  justify-content: start !important;
  padding: 0;
  padding-top: 0.5rem;
  // margin: 1rem 1rem 0.5rem !important;
  margin-bottom: 1rem;

}
.productId{
  font-family: "Avenir-Light";
  font-size: 1.4rem;
  color: #999;
  width: 100%;
  // text-align: start;
  // margin: auto;
  // overflow-wrap: anywhere;
  word-break: break-word;
  -ms-word-break: break-all;
  word-break: break-all;

  /* Non standard for WebKit */
  // word-break: break-word;
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;
  // word-break: break-all;
      
}

.headerExtra{
    display:flex!important;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    justify-content: center;
    width: 100%;
    text-align: center;
    font-weight: 100!important;
    border: none;
    padding: 1rem;
  }
  .ui.card.cardExtra-vs >.image.products{
    height: 80% !important;
  }

.iconWrap{
    font-size: 16px;
    font-weight: 100;
    border: none;
    display:flex;
    flex-direction: row;
    align-items: center;
}
.iconWrapVerified{
    @extend .iconWrap;
    color:$color-sr;
}

.iconWrapPending{
    @extend .iconWrap;
    color:#EDAE49;
}

i.iconExtra{
    margin:5px 0px 5px 5px;
}

i.iconTrashExtra{
    margin-top: -2px;
    color: $color-medium-gray;
    cursor: pointer;
}
i.iconAddExtra{
    margin-top: -2px;
    cursor: pointer;
    color: #c7ccd7;
    font-size:1.6rem!important;
}
.icon-large{
    display:flex;
    justify-content: center;
    font-size:1.8rem!important;
    margin:1.5rem!important;
}

.pagination {
    display: flex;
    direction: row;
    justify-content: center;
    align-items: center;
    margin:2rem 0 2rem 0;
    // margin-bottom: 1rem;
}
.pagination.absolute{
    position: absolute;
    @media only screen and (min-width: 769px){
        left: 15%;
        top:2.2rem;
    }
    @media only screen and (min-width: 900px){
        left: 15%;
        top:1.6rem;
    }
    @media only screen and (min-width: 1000px){
        left: 20%; 
        top:1.6rem;
    }
    @media only screen and (min-width: 1119px){
        left: 0; 
        right: 0; 
        margin-left: auto; 
        margin-right: auto; 
        top:1.6rem;
    }
    @media only screen and (min-width: 1360px){
        left: 0; 
        right: 0; 
        margin-left: auto; 
        margin-right: auto; 
        top:1.4rem;
    }
}

.pagination-unit{
    margin:2rem;
    font-size: $large-font-size;
    color: $color-dark-gray;
    font-weight: 100;
    cursor: pointer;
}
.circle-class{
    display: flex;
    background:rgba(85, 111, 181, 0.5);
    width: 4rem;
    height: 4rem;
    border-radius: 50%;
    cursor: pointer;
    justify-content: center;
    align-items: center;
}
.pagination-num{
    margin:0.8rem;
    cursor: pointer;
    
}


.selected-num{
    margin:1rem;
    cursor: pointer;
    
}

//////// from here
.pagination-item{
    display:flex;
    flex-direction: column;
    cursor: pointer;
    position: relative;
    // justify-content: center;
    align-items: center;
}
.circle-unit{
    display: flex;
    background: #EBEEF5;
    width: 3.5rem;
    height: 3rem;
    border-radius: 15px;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    &.sm{
      background: #DAF2F2;
    }
    &.sr{
      background: #DAF2F2;
    }
    &.as{
      background:#f2dade;
    }
    &.vs{
      background: #EBEEF5;
    }
  
   
}
.circle-unit-selected{
    display: flex;
    background: $color-at;
    width: 3.5rem;
    height: 3rem;
    border-radius: 15px;
    cursor: pointer;
    justify-content: center;
    align-items: center;

    &.sm{
        background: $color-sr;
    }
    &.sr{
      background: $color-sr;
    }
    &.as{
      background:$color-as;
    }
    &.vs{
      background: $color-vs;
    }
   
}

.selected-page{
    display:flex;
    height:0.5rem;
    width:80%;
    position: absolute;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    background: $color-purple;
    bottom:0px;
    margin-bottom:1rem;;
}


.selected-unit{
    // margin:2rem;
    font-family: "Avenir-Light";
    font-size: 1.2rem;
    color: $color-white;
    font-weight: bolder;
    cursor: pointer;
}
.option-unit{
    font-family: "Avenir-Light";
    font-size: 1.2rem;
    color: $color-purple;
    font-weight: bolder;
    cursor: pointer;
    &.sm{
        color:$color-sr;
      }
    &.sr{
      color: $color-sr;
    }
    &.as{
      color:$color-as;
    }
    &.vs{
      color: $color-vs;
    }
}

.scroll{
  display:flex; 
  overflow-x:scroll;
  max-width:250px;
  margin: 0 1rem;
  margin-bottom: 1rem;
}
.scroll::-webkit-scrollbar {
  width: 10px;
  height: 2.5px;
}
.scroll::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #EBEEF5;
  border-radius: 10px;
  &.sr{
    box-shadow: inset 0 0 5px #DAF2F2;
  }
  &.as{
    box-shadow: inset 0 0 5px#f2dade;
  }
  &.vs{
    box-shadow: inset 0 0 5px #EBEEF5;
  }
}
.scroll::-webkit-scrollbar-thumb {
  background: $color-at;
  border-radius: 10px;
  width: 70px;
}
.scroll::-webkit-scrollbar-thumb:hover {background: $color-at}
.scroll.sr::-webkit-scrollbar-thumb { background: $color-sr;}
.scroll.as::-webkit-scrollbar-thumb { background: $color-as;}
.scroll.vs::-webkit-scrollbar-thumb { background: $color-vs;}
.pagination >.option-unit-gray{
  color: #979ead;
}

//////////to here
/// 
/// 
/// 
/// 
/// 
/// 
/// 
.carousel .slider,
.carousel .slide,
 .carousel.carousel-slider, .carousel .slider-wrapper, .linked-img{
  height: 100% !important;
}
.linked-img{
  display:  inline-block; ;
  height: 100% !important;
}

.td-product-number{
    width: 5rem!important;
}
.align-text-table{
    text-align:center;
    color:#8e9aaf;
    // font-weight: bold;
}


// .pagination-solution-wrapper{
//     display: flex;
//     // flex-direction: column;
//     position:relative;
//     margin:5rem 0rem 2rem 0rem
// }

.horizontal-line-separator{
    width:100vw;
    z-index:50;
    overflow: hidden;
    background-color:#e6e6e6;
    height:3px;
    margin:2rem 0rem 0rem 0rem;
}

// .search-icon-sr{
//     display: flex;
//     color: $color-sr ;
//     background: white;
//     width: 4.5rem;
//     height: 4rem;
//     border-radius: 15px;
//     cursor: pointer;
//     justify-content: center;
//     align-items: center;
//     border:none;
//     outline:none;
// }
// .search-icon-sr.expanded{
//     border-bottom-left-radius: 15px;
//     border-top-left-radius: 15px;
//     border-top-right-radius: 0px!important;
//     border-bottom-right-radius: 0px!important;
// }
// .search-icon-autotag{
//     @extend .search-icon-sr;
//     color: $color-purple;
// }
// .search-icon-autotag.expanded{
//     @extend .search-icon-sr.expanded;
// }

.ui.card > .image {
  background-color: white;
  align-items: flex-end !important;
  border-radius: 2rem !important;
}

.ui.card>.image, .ui.cards>.card>.image {
  position: relative;
  display: block;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;

}

.image.products {
  border-radius: 2rem !important;
  padding: 1rem 1rem 0.5rem !important;
  border-radius: inherit;
  background-color: white;
  border-radius: 2rem !important;
}
.ui.card>.image>img, .ui.cards>.card>.image>img {
  display: block;
  width: 100%;
  height: auto;
  border-radius: inherit;
}
.form-search{
    display: flex;
    flex-direction: row;
}
.form-images{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}
.round-purple-button{
    background-color: $color-at;
    color: #fff;
    border-radius: 20px;
    border: none;
    z-index:99;
    font-size: 1.4rem;
    font-family: "Avenir-Light",sans-serif;
    font-weight: bold;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    text-align: center;
    width: 20rem;
    height: 4rem;
    cursor: pointer;
    outline: none;
    overflow:visible;
    &.disable{
      background-color: $color-light-gray;
      color: $color-medium-gray;
      cursor: default;
      &:hover{
        background-color: $color-light-gray !important;
        color: $color-medium-gray;
        cursor: default;
      }
    }
  }
  .round-purple-button:hover{
    background:$color-at-hover;
    @extend .transitionEffect;
  }
  .round-white-button{
      @extend .round-purple-button;
      color:$color-at;
      background: #fff;
        border:1px solid #c7ccd7;
        box-shadow: $small-shadow-light;
      margin:2rem 0rem
  }
  .round-white-button:hover{
    background:$color-white-gray;
    @extend .transitionEffect;
  }
  .white-icon-button{
      padding:1rem;
      height:4rem;
      border: none;
      z-index:99;
        border-radius: 20px;
      color:$color-at;
      background: #fff;
    font-family: "Avenir-Light",sans-serif;
      display:flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      outline: none;
      font-weight: bold;
      font-size:1.2rem;

      .text{
        padding:0.5rem 1rem;
        color:$color-at
      }
      .icon{
        color:white;
        border-radius: 25px;
        display: flex;
        flex-direction: column;
        // margin:1rem;
        width:2rem;
        padding-top:0.25rem;
        height:2rem;
        background-color:$color-at;
        font-size:1rem;;

        i {
            background-color: transparent;
            // padding:0!important;
          margin:0!important;
        }
      }
      .image{
          @extend .icon;
          width:4rem;
          height:4rem;
          display: flex;
          justify-content: center;
        //   margin-top:5px;
        background-color:transparent;
          img{
            width:3rem;
            height:3rem;
        }
      }
      &.vs{
          .text{
              color:$color-vs;
          }
          .icon{
              background-color: $color-vs;
          }
      }
     
  }
  .white-icon-button.with-border{
      border:1px solid #c7ccd7 !important;
  }
  .white-icon-button:hover{
      background:$color-white-gray;
      box-shadow: $small-shadow-light;
      @extend .transitionEffect;
  }

  .white-icon-button.solution-btn{
    font-weight: 500;
    font-size:1.6rem;
    margin:0 4rem  3rem 0;
    height:5rem;
    cursor: pointer;
    .text{
        color:$color-dark-gray;
    }
  }
  .white-icon-button.solution-btn.disabled{
    // cursor:pointer;  
    .text{
        color:$color-light-gray;
      }
  }


  .filled-icon-button{
    //   @extend 
      @extend .white-icon-button;
      background:$color-at;
      color: #fff;
      .text{
        color:#fff!important;
      }
      .icon{
        color:$color-at;
        background-color:white;
      }
      &.vs{
        background: $color-vs;
        .icon{
            color:$color-vs;
            background-color:white;
        }
      }

  }
  .filled-icon-button:hover{
    background:$color-at-hover;
    @extend .transitionEffect;
   
    &.vs{
      background: $color-vs-hover;
    }
    }
  .button-with-margin{
      margin:2rem 1rem;
  }
  p.or{
    display: flex; 
    justify-content: center;
    align-items: center;
    height:4rem;
    margin:0 4rem;
    font-size: 1.4rem;
    font-family: "Avenir-Light",sans-serif;
    font-weight: bold;
    color: #979EAD;
    @media (max-width:768px){
        margin:0 2rem;
    }
  }

      .cardExtra-vs > .image .products {
        border-radius: 2rem !important;
      }

  // mediaQ

    // @media screen and (max-width: 1113px) {
  //   .scroll{
  //     max-width: 230px;
  //   }}
  //  @media screen and (max-width: 835px) {
  //     .scroll{
  //       max-width: 200px;
  //   }} 
  @media screen and (max-width: 1601px) {
    .ui.card.cardExtra-vs > .image.products{
      height: 75% !important;
    }
    .cardExtra {
      height: 33rem;
  }
  .segment.sm-img {
    padding-left: 1rem;
}
} 
@media screen and (max-width: 1025px) {
  .cardWrapper {
    width: 50% !important;
  }
  .cardExtra {
    height: 39rem;
  }
  .ui.card.cardExtra-vs > .image.products{
    height: 80% !important;
  }
} 
// @media screen and (max-width: 815px) {
//   .segment.sm-img .columnImg .img-div img {
//     width: 5rem;
//     height: 7rem;
//     padding: 3px;
//   }

// } 
// @media screen and (max-width: 915px) {
//   .segment.sm-img .columnImg .img-div img {
//     width: 5rem;
//     height: 7rem;
//     padding: 3px;
//   }

// } 
@media screen and (max-width: 920px) {
  .container.header {
    flex-direction: column;
  }
  .segment.sm-img {
    margin-top: 3rem;
    width: 100% !important;
    padding-left: 2rem;
}
.columnUploadedImageInput {

  width: 100%;
}
.ui.grid.at {
  display: flex !important;
  flex-direction: column;
}
.ui.column.grid > [class*="eight wide"].column, .ui.grid > .column.row > [class*="eight wide"].column, .ui.grid > .row > [class*="eight wide"].column, .ui.grid > [class*="eight wide"].column.p-img {
  width: 100% !important;
}
.ui.column.grid > [class*="eight wide"].column, .ui.grid > .column.row > [class*="eight wide"].column, .ui.grid > .row > [class*="eight wide"].column, .ui.grid > [class*="eight wide"].column.s-img {
  width: 100% !important;
}
}

@media screen and (max-width: 415px) {
  .segment.sm-img {
    margin-top: 3rem;
    width: 100% !important;
    padding-left: 0rem;
}
  // .segment.sm-img .columnImg .img-div img {
  //   width: 4.5rem;
  //   height: 6rem;
  
  // }

} 
// @media screen and (max-width: 376px) {
//   .segment.sm-img .columnImg .img-div img {
//     width: 4rem;
//     height: 5rem;
//   }

// } 
// @media screen and (max-width: 320px) {
//   .segment.sm-img .columnImg .img-div img {
//     width: 3.5rem;
//     height: 5rem;
//   }

// } 